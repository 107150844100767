<template>
  <div justify="end">
    <v-dialog v-model="isVisibleDialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" small class="mx-5" v-bind="attrs" v-on="on">
          {{ $t("import_batch_csv") }}
        </v-btn>
      </template>
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("inventory_batch_csv") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="6">
                      <v-file-input
                        class="pt-4"
                        dense
                        hide-details="auto"
                        outlined
                        @change="onFileChanged($event)"
                        :key="fileInputKey"
                        type="file"
                        label="csv"
                        prepend-icon=""
                        append-icon="mdi-file"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        class="pt-4"
                        dense
                        hide-details="auto"
                        outlined
                        :label="$t('count_number')"
                        v-model="count_number"
                        :items="count_list"
                        item-value="id"
                        item-text="name"
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete
                        clearable
                        v-model="user"
                        :items="Users"
                        item-text="name"
                        item-value="id"
                        :label="$t('users')"
                        :search-input.sync="search_user"
                        :loading="is_loading_users"
                        no-filter
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="upload_error">
                    <v-col cols="12">
                      <v-alert type="error" dense outlined class="pa-2 mb-0">
                        {{ upload_error }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="uploadCSV(products)"
              :disabled="!is_valid"
              :loading="is_uploading"
            >
              {{ $t("upload") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import debounce from "lodash/debounce";
export default {
  mixins: [validationRules],

  props: {
    InventoryData: {
      default() {
        return "";
      },
    },
    Users: {
      default() {
        return "";
      },
    },
  },
  watch: {
    search_user: debounce(function (search) {
      if (this.is_loading_users) return;
      this.is_loading_users = true;

      this.$store
        .dispatch("users/list", {
          itemsPerPage: 30,
          search,
        })
        .then(() => {
          this.is_loading_users = false;
        });
    }, 1000),
  },

  data() {
    return {
      search_user: "",
      is_loading_users: false,
      upload_error: "",
      products: {},
      is_valid: true,
      is_uploading: false,
      isVisibleDialog: false,
      user: 1,
      fileInputKey: 0,
      count_list: [
        { id: "1", name: this.$t("count_1") },
        { id: "2", name: this.$t("count_2") },
      ],
      count_number: 1,
    };
  },
  methods: {
    closeForm() {
      // this.$refs.form.reset();
      this.upload_error = "";
      this.fileInputKey++;
      this.isVisibleDialog = false;
      this.is_uploading = false;
      this.products = {};
      this.count_number = 1;
    },

    async uploadCSV(products) {
      if (this.$refs.form.validate()) {
        this.InventoryData.count_number = this.count_number;
        this.InventoryData.selected_user = this.user;
        let data_to_send = {
          products: products,
          InventoryData: this.InventoryData,
        };
        try {
          this.is_uploading = true;
          this.upload_error = "";
          await this.$store.dispatch(
            "inventories/uploadBATCHCSV",
            data_to_send
          );
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          this.is_uploading = false;
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.upload_error = error.response?.data?.message;
          this.is_uploading = false;
        }
        this.is_uploading = false;
      }
    },

    onFileChanged(e) {
      this.products.csv = e;
    },
  },
};
</script>
